// Persian
export default{
    form: {
        startSurveyButton: "شروع کنید",
        nextButton: "بعدی",
        submitAnswerButton: "ارسال پاسخ",
        continueButton: "ادامه دهید",
        finishButton: "پایان",
        headerPercentageCompletion: 'تکمیل شده',
        headerQuestionsCompleted: 'سوالات',
        headerDefaultErrorMessage: 'اوه. مشکلی پیش آمد.',
        brandingPoweredBy: 'ارائه شده توسط',
        brandingPromoWhySurvey: 'چرا نظرسنجی وقتی می‌توانید از <b>{product}</b> استفاده کنید؟',
        brandingPromoGetStarted: 'همین امروز شروع کنید!',
        submissionPending: "در حال ارسال فرم صوتی شما. لطفاً پنجره مرورگر را نبندید.",
        submissionCanCloseWindow: "فرم صوتی شما با موفقیت ارسال شد. اکنون می‌توانید پنجره را ببندید 👍",
        mediaAttachmentOverlayCloseButton: "بستن",
        expiredFormHeader: "از بازدید شما متشکریم!",
        expiredFormMessage: "به نظر می‌رسد این نظرسنجی منقضی شده است. لطفاً با سازنده فرم صوتی تماس بگیرید.",
        notPublishedFormHeader: "فرم صوتی منتشر نشده است!",
        notPublishedFormMessage: "به نظر می‌رسد این نظرسنجی هنوز منتشر نشده است. لطفاً با سازنده فرم صوتی تماس بگیرید.",
        notPublishedFormRetryButton: "دوباره امتحان کنید",
        errorFormHeader: "اوه!",
        errorFormMessage: "ممکن است مشکلی پیش آمده باشد. لطفاً بعداً دوباره تلاش کنید.",
        errorFormRetryButton: "دوباره امتحان کنید",
        emptyFormHeader: "از بازدید شما متشکریم!",
        emptyFormMessage: "اوه! به نظر می‌رسد این فرم در حال حاضر خالی است و هیچ سوال یا فیلدی برای پر کردن ندارد. ما بابت این مشکل پوزش می‌طلبیم. برای حل این مشکل، لطفاً با سازنده فرم تماس بگیرید. متشکریم!",

        restoreFormHeader: "به نظر می‌رسد که شما یک فرم در حال پیشرفت دارید!",
        restoreFormMessage: "آیا می‌خواهید از همان‌جا که متوقف شده بودید ادامه دهید؟",
        restoreFormButton: "ادامه فرم",
        restoreFormButtonNew: "شروع دوباره",

        audioPermissionHeader: "آیا مایلید با صدا پاسخ دهید؟",
        audioPermissionMessage: "صحبت کردن، پاسخگویی را سریع و آسان می‌کند.",
        audioPermissionAcceptButton: "بله، می‌خواهم",
        audioPermissionDenyButton: "نه، متشکرم",
        audioPermissionErrorHeader: "میکروفون در دسترس نیست",
        audioPermissionErrorMessage: "لطفاً دسترسی به میکروفون را در مجوزهای مرورگر خود فعال کنید.",
        audioPermissionErrorButton: "باشه",
        audioRecorderFailedUpload: "آپلود ضبط صوتی ناموفق بود",
        silenceDetectorError: "ما صدای شما را نمی‌شنویم. لطفاً تنظیمات میکروفون خود را بررسی کنید. نکته: ممکن است میکروفون خارجی شما باشد.",
        audioRecorderButtonRecord: "شروع ضبط",
        audioRecorderButtonRecordMore: "ضبط بیشتر",
        voiceResponseSelectionMessage: "نحوه پاسخ‌دهی خود را انتخاب کنید...",
        voiceResponseSelectionOrDivider: "یا",
        voiceResponseTextInputPlaceholder: "پاسخ را تایپ کنید",
        voiceResponseTextInputCharacterCounterMinMessage: "لطفاً حداقل {min} کاراکتر وارد کنید",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} کاراکتر (حداقل {min} کاراکتر)",
        voiceResponseTextInputCharacterValidationMessage: "{count} کاراکتر (حداقل {min} کاراکتر)",

        // checkbox
        checkboxValidationTooFew: "شما باید حداقل {min} گزینه را انتخاب کنید",
        checkboxValidationTooMany: "شما باید حداکثر {max} گزینه را انتخاب کنید",
        checkboxNoneOfTheAboveOption: "هیچ‌یک از موارد بالا",
        checkboxInvalidAnswer: "پاسخ نامعتبر.",

        // datepicker
        datePickerPlaceholder: "تاریخ را انتخاب کنید",

        // dropdown
        // email
        emailLabel: "ایمیل",
        emailInvalid: "ایمیل نامعتبر.",
        // file-upload
        fileUploadPluginNameCamera: "دوربین",
        fileUploadPluginNameCameraVideo: "ضبط ویدیو",
        fileUploadPluginScreenCast: "اشتراک صفحه",
        fileUploadVideoImportFilesDefault: 'از طریق این موارد ویدیو ضبط یا آپلود کنید:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'یک گزینه برای ضبط ویدیو انتخاب کنید:',
        fileUploadVideoImportFilesNoCamera: 'برای ضبط ویدیوی صفحه، یک دکمه را بزنید',
        fileUploadVideoImportFilesNoScreenCast: 'برای ضبط ویدیو، یک دکمه را بزنید',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'هیچ گزینه‌ای برای ضبط ویدیو در دسترس نیست',

        // matrix
        matrixValidationMessage: "پاسخ معتبر است.",
        matrixRow: "ردیف",
        matrixColumn: "ستون",
        matrixRowRequired: "ردیف {rowTitle} الزامی است.",
        matrixRadioGroup: "گروه رادیویی",
        matrixCheckboxGroup: "گروه چک‌باکس",
        matrixGroupRequired: "{type} {groupTitle} برای ردیف {rowTitle} لازم است.",
        matrixColumnRequired: "ستون {columnTitle} برای ردیف {rowTitle} لازم است.",
        matrixColumnInvalid: "ستون {columnTitle} برای ردیف {rowTitle} نامعتبر است.",
        matrixRequired: "لازم است.",
        matrixNumericMustBeNumber: "باید عدد باشد.",
        matrixNumericMustBeGreaterThenMin: "باید بیشتر از {min} باشد.",
        matrixNumericMustBeLessThenMax: "باید کمتر از {max} باشد.",
        matrixNumericMustBeInteger: "باید عدد صحیح باشد.",
        matrixNumericInvalidNumber: "عدد نامعتبر.",

        // name
        nameLabel: "نام",
        nameInvalid: "نام و نام خانوادگی را مشخص کنید.",

        // nps
        npsNotLikely: "اصلاً محتمل نیست",
        npsExtremelyLikely: "خیلی محتمل",

        // numeric input
        numericInputRequired: "لازم است.",
        numericInputMustBeNumber: "باید عدد باشد.",
        numericInputMustBeGreaterThenMin: "باید بیشتر از {min} باشد.",
        numericInputMustBeLessThenMax: "باید کمتر از {max} باشد.",
        numericInputMustBeInteger: "باید عدد صحیح باشد.",
        numericInputInvalidNumber: "عدد نامعتبر.",
        numericInputPlaceholder: "عدد وارد کنید",

        // phone
        phoneInvalid: "شماره تلفن نامعتبر است.",
        phoneCountrySelectorLabel: 'کد کشور',
        phoneCountrySelectorError: 'کشور را انتخاب کنید',
        phoneNumberLabel: 'شماره تلفن',
        phoneExample: 'مثال:',

        // boolean
        booleanYesLabel: "بله",
        booleanNoLabel: "خیر",

        //questionStep
        questionStepAudioQuestionLabel: "سوال صوتی",

        // errors
        invalidPhoneNumber: "{phone} یک شماره تلفن نامعتبر است.",
        invalidEmail: "{email} یک آدرس ایمیل نامعتبر است.",
        questionIsRequired: "این سوال الزامی است.",
        answerIsNotValid: "پاسخ معتبر نیست.",
        unfinishedProbingDialogError: "لطفاً مکالمه را به پایان برسانید.",
        cannotResumePartialResponse: "امکان از سرگیری پاسخ جزئی نیست.",
        failedToSubmitForm: "ارسال فرم ناموفق بود. لطفاً اتصال اینترنت خود را بررسی کنید و دوباره تلاش کنید."
    }
}
